import React from 'react'

const NewYear2 = () => {
  return (
    <svg
      className="newyear2"
      viewBox="0 0 293.02 140.89"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: '0' }}
    >
      <clipPath id="clip2">
        <path d="M60.26,106.77l-.21.62,1,.82-1.22,1.22.41.21-1.23.82v.2l.61.21a4.7,4.7,0,0,1-2,1.43c0,.82-.62.82-1.43,1.63a2.77,2.77,0,0,0-1.44.62h.21c-1,.82-2.46,1.43-2.87,2.45h1.44l-.21.62c-1.23.61-4.5,3.68-5.32,3.88-6.54,2.66-19.23,7.16-26.39,7.16-5.93,0-12.06-6.54-12.06-11.86,0-25.78,60.14-65,60.14-78.35,0-5.93-8.39-8-12.69-8-9.81,0-16.57,5.32-24.54,10-1-2.66-3.07-4.3-5.32-7.57-.41-1,0-1,.41-1.64l-.41-1.43c-1.23-6.75,18-12.27,24.34-12.27,13.09,0,27.82,8,27.82,22.91,0,12.07-14.52,27.41-22.3,35.39-1.84,1.63-4.09,4.5-5.93,5.72-1.43,2.25-4.7,4.91-6.75,7-4.09,4.09-23.52,24.55-23.52,31.09v.41C31.82,118.23,48.6,109.23,60.26,106.77Z" />
        <path d="M92,104.32C91.56,77.11,108.13,43.57,116.31,27c1-.82,1.64-2.46,2.45-3.28V22.5c1.23-1.23,2-1.85,2.46-3.07V18.2l1.23-1.64c.61,0,.81.21,1.22.21.21,0,.41-.21,1-.41h1.43a2.54,2.54,0,0,0,1.23-.21A.74.74,0,0,1,128,16c2,0,5.52,3.89,5.52,5.11l.21.41a20.27,20.27,0,0,1-1.23,4.5v.41c22.3,6.75,17.18,36.82,13.09,54-4.29,17-15.75,48.68-37,48.68C96.26,128.25,92.17,113.93,92,104.32Zm36.82-68.53c-5.93,9.41-22.1,54.82-21.48,71.6,0,2.45-.62,12.68,2.86,12.68h1.23c12.27-9.2,26-48.28,25.57-69.35-.2-8-3.48-15.75-8.18-15.75Z" />
        <path d="M218,106.77l-.21.62,1,.82-1.22,1.22.41.21-1.23.82v.2l.61.21a4.76,4.76,0,0,1-2,1.43c0,.82-.62.82-1.44,1.63a2.78,2.78,0,0,0-1.43.62h.21c-1,.82-2.46,1.43-2.87,2.45h1.44l-.21.62c-1.23.61-4.5,3.68-5.32,3.88-6.54,2.66-19.23,7.16-26.39,7.16-5.93,0-12.07-6.54-12.07-11.86,0-25.78,60.15-65,60.15-78.35,0-5.93-8.39-8-12.69-8-9.82,0-16.57,5.32-24.54,10-1-2.66-3.07-4.3-5.32-7.57-.41-1,0-1,.41-1.64l-.41-1.43c-1.23-6.75,18-12.27,24.34-12.27,13.09,0,27.82,8,27.82,22.91,0,12.07-14.52,27.41-22.3,35.39-1.84,1.63-4.09,4.5-5.93,5.72-1.43,2.25-4.7,4.91-6.75,7-4.09,4.09-23.52,24.55-23.52,31.09v.41C189.54,118.23,206.32,109.23,218,106.77Z" />
        <path d="M264,129.89l-1.22-.61v1l-.62.2-.41,1.44-1-1.44c-7.16-5.31-6.54-17.38-6.54-25.77.2-12.27,5.52-32.53,11-50.53,1.84-6.54,6.34-19.43,6.55-26,0-.82-1-1.84-.61-2.66,0-2.25,6.75-4.91,9-4.7,1,0,2,.41,2.86.41,2.86,2.45,1.64,7,1.64,10-.82,9.62-4.5,20.87-7,30.07-6.14,21.28-10,38.66-11.46,60.76a24.72,24.72,0,0,1-.4,5.72,2,2,0,0,1-.62,1.64l.21,1.64C265,130.71,264.21,130.3,264,129.89Z" />
      </clipPath>

      <path
        className="anim2"
        clipPath="url(#clip2)"
        d="M19.62,43.1C31.22,32,46.71,20.35,58.76,23.41S75.79,38.54,72.08,46,57.27,69.12,31.22,91.79a51.8,51.8,0,0,0-13.1,17.83c-4.7,9.95,1.2,17.1,9.77,14.12s27.74-10.63,33-14.6S80.06,65.77,80.06,65.77,102.82,9.28,107.92,7.06s26.76,0,26.76,0-2,4-5,10C123,30.63,115.22,44.94,111.55,56,106.24,72,97,86.58,99.27,105.16c1,8,2.6,17.1,12.26,19.58,5.93,1.53,18.81-22.55,23.15-31.47s9.13-29.7,8.81-43.11c-.37-15.69-14-18.85-10.6-25.27,6-11.16,36.37,15.13,45.16,12.64,10.78-3.06,24.72-16,41.25-15.24,9,.41,15.48,10.41,12.39,19.7s-17.58,23.57-25,31.95c-3.22,3.63-24.9,23.79-32.47,37.17-3.7,6.56.88,11.83,7.57,11.15s28.32-9.72,35.53-11.18,27.27-41.86,29.13-45.26S256.78,18,259,17.59s18.45-4,19.38-.51c2.6,9.67-1.74,29.45-4.52,36.08-11.37,27.12-12.95,46.43-12.95,46.43-1.11,6-1.11,16.72,3.74,29.8,2.1,5.68,4.89,8.23,4.89,8.23"
      />
    </svg>
  )
}

export default NewYear2
