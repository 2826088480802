import React from 'react'

import NewYear1 from './parts/NewYear1'
import NewYear2 from './parts/NewYear2'
import UnderLine from './parts/UnderLine'
import Shine from './parts/Shine'
import styles from './NewYear.module.scss'

import styled from 'styled-components'

const CircleL = () => {
  return <div className="circle-l"></div>
}
const CircleS = () => {
  return <div className="circle-s"></div>
}

const DemoWrapper = styled.div`
  svg {
    position: absolute;
    z-index: 1;
    overflow: initial;
    animation: visible 0.3s forwards linear;
    @keyframes visible {
      to {
        opacity: 1;
      }
    }
  }
  .circles {
    .circle-l {
      height: 30px;
      width: 30px;
    }
    .circle-s {
      height: 12px;
      width: 12px;
    }
    .circle-l,
    .circle-s {
      background-color: #c6ba9b;
      border-radius: 50%;
      position: absolute;
      animation: scaleOut 0.5s forwards ease-out;
      animation-iteration-count: 3;
      opacity: 0;
      &:nth-of-type(1) {
        left: 9%;
        top: 2%;
        animation-delay: 6s;
      }
      &:nth-of-type(2) {
        right: 10%;
        top: 10%;
        animation-delay: 6.2s;
      }
      &:nth-of-type(3) {
        left: 1%;
        top: 80%;
        animation-delay: 6.4s;
      }
      &:nth-of-type(4) {
        right: 30%;
        top: 95%;
        animation-delay: 6.7s;
      }
      &:nth-of-type(5) {
        right: 28%;
        top: 22%;
        animation-delay: 6.8s;
      }
      &:nth-of-type(6) {
        left: 16%;
        top: 55%;
        animation-delay: 7.1s;
      }
      &:nth-of-type(7) {
        left: 27%;
        top: 16%;
        animation-delay: 6.7s;
      }
      &:nth-of-type(8) {
        right: 5%;
        bottom: 24%;
        animation-delay: 6.5s;
      }
      &:nth-of-type(9) {
        left: 34%;
        bottom: 8%;
        animation-delay: 6.3s;
      }
      @keyframes scaleOut {
        0% {
          opacity: 0;
          transform: scale3d(0, 0, 0);
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
  .greeting {
    max-width: 600px;
    margin: auto;
    svg.newyear1 {
      width: 100%;
      height: 140px;
      top: 75px;
      .anim1 {
        fill: none;
        stroke: #c5ba9a;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 10px;
        stroke-dasharray: 2824.187744140625;
        stroke-dashoffset: 2824.187744140625;
        animation: draw 2.7s linear 0.5s forwards;
      }
    }
    svg.newyear2 {
      width: 158px;
      height: 88px;
      margin: auto;
      top: 190px;
      left: 0;
      right: 0;
      /* @media only screen and (max-width: 350px) {
        height: 76px;
      }
      @media only screen and (min-width: 351px) and (max-width: 400px) {
        height: 89px;
      }
      @media only screen and (min-width: 401px) and (max-width: 679px) {
        height: 98px;
      } */
      @media only screen and (min-width: 680px) {
        width: 100%;
        height: 158px;
        top: 225px;
      }
      .anim2 {
        fill: none;
        stroke: #c5ba9a;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 10px;
        stroke-dasharray: 1116.7513427734375;
        stroke-dashoffset: 1116.7513427734375;
        animation: draw 1.2s linear 3.2s forwards;
      }
    }
    @keyframes draw {
      to {
        stroke-dashoffset: 0;
      }
    }
    svg.underline {
      width: 88%;
      top: 180px;
      right: 0;
      width: 86%;
      @media only screen and (min-width: 680px) {
        top: 200px;
      }
      .underlineAnim {
        fill: none;
        stroke: #c5ba9a;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 10px;
        stroke-dasharray: 1452.0181884765625;
        stroke-dashoffset: 1452.0181884765625;
        animation: draw 0.4s linear 5.3s forwards;
      }
    }
    svg.shine {
      position: absolute;
      top: 40px;
      right: 0;
      left: 0;
      margin: auto;
      width: 100px;
      .shineAnim {
        fill: none;
        stroke: #c5ba9a;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 10px;
        stroke-dasharray: 441.998291015625;
        stroke-dashoffset: 441.998291015625;
        animation: draw 0.5s linear 4.6s forwards;
      }
    }
  }
`
// const welcome = document.querySelectorAll('.anim2')

// for (let i = 0; i < welcome.length; i++) {
//   console.log(`Letter ${i} is ${welcome[i].getTotalLength()}`)
// }

const NewYear = () => {
  return (
    <div className={styles.root}>
      <DemoWrapper className={styles.inner}>
        <div className="greeting">
          <Shine />
          <NewYear1 />
          <NewYear2 />
          <UnderLine />
        </div>
        <div className="circles">
          <CircleL />
          <CircleL />
          <CircleL />
          <CircleL />
          <CircleS />
          <CircleS />
          <CircleS />
          <CircleS />
          <CircleS />
        </div>
      </DemoWrapper>
    </div>
  )
}

export default NewYear
