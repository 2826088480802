import React from 'react'

const UnderLine = () => {
  return (
    <svg
      className="underline"
      viewBox="0 0 503.62 183.84"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: '0' }}
    >
      <clipPath id="underlineClip">
        <path d="M2.53,1.6A34.84,34.84,0,0,0,8.64.67C12.1.27,492.05,0,495.64,0c2.13,0,4.52,0,6.38,1.06.53.67,1.6,1.73,1.6,2.93,0,0,0,.4-.14.4V5.45c-.13,0-.93,0-.93.4H498.7c-1.07.13-2.53.13-3.86.4-5.19.27-486.86,2-492.18,2A3.2,3.2,0,0,1,0,5.05c0-1.72.8-2.39,1.6-4A.88.88,0,0,0,2.53,1.6Z" />
        <path d="M112.59,176.29a45.89,45.89,0,0,0,7.56-1c4.27-.46,244.18-.76,248.61-.76,2.63,0,5.59,0,7.89,1.21.66.75,2,2,2,3.32,0,0,0,.45-.16.45v1.21c-.17,0-1.15,0-1.15.45h-4.77c-1.31.15-3.12.15-4.76.45-6.41.31-248.45,2.27-255,2.27a3.75,3.75,0,0,1-3.29-3.62c0-2,1-2.72,2-4.53A1.12,1.12,0,0,0,112.59,176.29Z" />
      </clipPath>

      <path
        className="underlineAnim"
        clipPath="url(#underlineClip)"
        d="M-9.17,4.12c38.42-.09,540,0,540,0S373.64,78,317.73,91.92c-71.38,17.8-286.31,87.24-286.31,87.24h382"
      />
    </svg>
  )
}

export default UnderLine
