import React, { useEffect } from 'react'
import Layout from '../../components/Demo/Layout'
import NewYear from '../../components/Demo/NewYear'

const DemoNewYearPage = () => {
  const pageSlug = 'newyear2021'

  return (
    <Layout pageSlug={pageSlug}>
      <NewYear />
    </Layout>
  )
}

export default DemoNewYearPage
