import React from 'react'

const Shine = () => {
  return (
    <svg
      className="shine"
      viewBox="0 0 142.6 71.8"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: '0' }}
    >
      <clipPath id="shineClip">
        <path d="M8.68,17.2a127.54,127.54,0,0,0,5.53,12.71c3,7.24,5.94,15.1,8.5,22.79,1.51,4.55,3.21,9.68,3.18,14.11-.46,1.42-1.06,4.15-2.58,4.65,0,0-.5.17-.6-.11l-1.35.45c-.09-.29-.66-2-1.17-1.83l-2.74-8.26c-.92-2.22-2-5.35-3.25-8.09-4-11-10.09-21.94-13.87-33.33-.85-2.56,0-6.31,2.16-7S6.1,14,8.69,15A2.53,2.53,0,0,0,8.68,17.2Z" />
        <path d="M74.49,5.7a125.36,125.36,0,0,0,1.25,13.8c.53,7.8.89,16.21.89,24.31,0,4.8,0,10.2-1.43,14.4-.89,1.2-2.31,3.6-3.91,3.6,0,0-.53,0-.53-.3H69.33c0-.3,0-2.1-.53-2.1v-8.7c-.18-2.4-.18-5.7-.53-8.7C67.91,30.3,65.6,18,65.6,6c0-2.7,2-6,4.27-6s3.2,1.8,5.33,3.6A2.55,2.55,0,0,0,74.49,5.7Z" />
        <path d="M141.26,18.55A127.52,127.52,0,0,0,138.08,32c-2,7.57-4.29,15.65-6.85,23.34-1.52,4.55-3.23,9.68-5.9,13.21-1.23.86-3.34,2.68-4.86,2.18,0,0-.5-.17-.41-.46l-1.35-.45c.1-.28.67-2,.16-2.16l2.75-8.25c.59-2.34,1.64-5.47,2.25-8.43,3.36-11.21,5.06-23.61,8.86-35,.85-2.57,3.75-5.08,6-4.35s2.47,2.72,3.92,5.11A2.56,2.56,0,0,0,141.26,18.55Z" />
      </clipPath>

      <path
        className="shineAnim"
        clipPath="url(#shineClip)"
        d="M25.89,74.48C23.31,59.5,0,13.05,0,2S33.21,3,36.11,23.26,68.3,78,71.39,59.49C75,37.75,65.5,3.86,72.34-8.22c5.72-10.07,27.35,20.28,29.49,39.12s-3.23,44.77,7.73,44.79c20,0,29.36-72.84,29.36-72.84"
      />
    </svg>
  )
}

export default Shine
